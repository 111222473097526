import {
  Autocomplete,
  Badge,
  Button,
  Checkbox,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { i18n } from "../util/i18n";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;


const CustomExpandMoreIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 10L12 16L18 10" stroke="#304567" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CheckedIcon = () => (
  <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckBoxIcon">
    <rect x="4" y="4" width="16" height="16" fill="#304567"></rect>
    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="white"></path>
  </svg>
);

import {
  getAllOptionsForFilter, // For all options that are available
  filtersParamsToOptions, // For the selected options / current filters
  optionsToFiltersParams, // For converting the selected options back to filtersParams
} from "../util/Conversions";

/**
 * 

 */
const AttributeFilterDropdown = ({
  filtersParams,
  setFiltersParams,
  attributes,
  filterAttributeId,
  onSelectionChanged,
  mode = "single", // or "multiple" or "tree" (later)
  label = "Filter",
  placeholder,
  ...props
}) => {
  if (!attributes) {
    return <div>Project Attributes are required</div>;
  }
  if (!filterAttributeId) {
    return <div>Filter attribute ID is required</div>;
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownIcon = dropdownOpen ? <CloseIcon /> : <CustomExpandMoreIcon />;

  // Get all the available options for the filter. This does typically not change over time per filter component.
  // If there are no available options, we don't render anything.
  // Mode "top" means that we only show the top level options, not the children.
  const options = getAllOptionsForFilter(filterAttributeId, attributes, false, true);

  if (!options) {
    return <></>;
  }

  // The currently selected Options
  const selection = filtersParamsToOptions(filtersParams, attributes)[
    filterAttributeId
  ];
  const [selectedOptions, setSelectedOptions] = useState(selection);

  function arrayDiff(a, b) {
    return a.filter((objA) => !b.some((objB) => objA.value === objB.value));
  }

  const count_children_selected = (option, selectedOptions) => {
    console.log("count_children_selected: option", option, selectedOptions);
    console.log("count_children_selected: filterParams", filtersParams);

    if (!option.has_children) {
      return 0;
    }

    return selectedOptions[option.children_filter_id]?.length || 0;
  };

  /**
   *
   * @param {*} event
   * @param {*} value
   */
  const handleChanged = (event, value, reason) => {

    let isParent = event.target.className.includes("parent"); // Proud of yourself??

    console.log("handleChanged: target", event.target.className);
    console.log("handleChanged: isParent", isParent);

    console.log("handleChanged: Current selected options", selectedOptions);
    console.log("handleChanged: filterId", filterAttributeId);

    // remove parent/group options
    const newValue = value;
    const diffAdded = arrayDiff(newValue, selectedOptions);
    const diffRemoved = arrayDiff(selectedOptions, newValue);

    if (onSelectionChanged) {
      if (diffAdded.length > 0) {
        // parent added ? only trigger callback with the parent option

        onSelectionChanged(diffAdded[0]);
        console.log("Event ", event.target)
        setDropdownOpen(! isParent)

      }
    }

    /**
     * This was a click on a single option: set value as new filtersParams.
     */
    const selected_options = {
      [filterAttributeId]: value,
    };
    const newParams = optionsToFiltersParams(selected_options, filtersParams);
    console.log (">> newParams after selection", newParams)
    setFiltersParams(newParams);
  };

  useEffect(() => {
    // FiltersParams changed, update selection of component
    const selection = filtersParamsToOptions(filtersParams, attributes)[
      filterAttributeId
    ];
    console.log("handleChanged, filtersParams", filtersParams);

    console.log("handleChanged, selection", selection);
    setSelectedOptions(selection || []);


  }, [filtersParams]);



  // New functionality to select all or reset all
  const handleSelectAll = useCallback(() => {

    let allOptions = options
      .filter((option) => { return !option.has_children })
      // .reduce((prev, current) => {

      //   return {
      //     ...(prev || {}),
      //     [current.filter_id]: (prev[current.filter_id] || "") + "," + current.value
      //   }


      // })

    let newf = {

    }

    console.log("handleSelectAll allOptions:", allOptions)

    for (var i = 0; i < allOptions.length;i++) {
      let option = allOptions[i]
      if (newf[option.filter_id]) {
        newf[option.filter_id] = newf[option.filter_id] +","+ option.value
      } else {
        newf[option.filter_id] = option.value
      }
    }



    console.log("handleSelectAll filterOptions f:" + filterAttributeId, newf)
    console.log("handleSelectAll filterOptions length", Object.keys(newf).length)



    setFiltersParams((prevFiltersParams) => ({
      ...prevFiltersParams,
      f: {
        ...prevFiltersParams.f,
        ...newf
      },
    }));


  }, [options, filterAttributeId, setFiltersParams]);




  const handleResetAll = useCallback(() => {
    setFiltersParams((prevFiltersParams) => {

      let newf = {}
      for (var key in prevFiltersParams?.f) {
        if (!key.startsWith(filterAttributeId)) {
          newf[key] = prevFiltersParams.f[key]
        }
      }


      let result =
      {
        ...prevFiltersParams,
        f: newf,
      }

      console.log("handleResetAll filterOptions f:" + filterAttributeId, result)

      return result;

    });

  }, [filterAttributeId, setFiltersParams]);

  // see: https://mui.com/material-ui/api/autocomplete/
  return (
    <>
      <Autocomplete
        disablePortal={false}
        //open
        open={dropdownOpen}
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
        className="dropdown-group"
        id={`attribute-filter-group-${filterAttributeId}`}
        options={[{ label: 'Actions', SelectAll: true }, ...options]}
        value={selectedOptions || []}
        multiple={true}
        onChange={handleChanged}
        fullWidth={true}
        limitTags={3}
        popupIcon={dropdownIcon}
        disableCloseOnSelect={true}

        componentsProps={{}}
        renderTags={(value, getTagProps, ownerState) => {
          // console.log("badge ownerstate", value)
          // // count all the children ids for the main parent filter (like "80")
          // let count = 0
          // for (var key in filtersParams?.f) {
          //   if (key.startsWith(filterAttributeId) ) {
          //     count += filtersParams?.f[key].split(",").length
          //   }
          // }
          // return <Badge sx={{ mx: 0 }} badgeContent={count}></Badge>;
          return <></>
        }}
        isOptionEqualToValue={(option, value) => {
          return option.value == value.value;
        }}
        getOptionLabel={(option) => {
          return option?.label || "";
        }}



        /*
        *
        */
        renderOption={(props, option, { selected }) => {
          console.log ("renderOption", option)
          if (option.hidden) {
            return <li {...props} style={{ display: "none" }}></li>
          }

          if (option.SelectAll) {
            // Determine if all options are selected or none are selected
            const allSelected = options.length === selectedOptions?.length;

            console.log ("reset", filtersParams.f)
            const noneSelected = Object.keys (filtersParams.f).filter((k) => k.startsWith (filterAttributeId)).length === 0 //selectedOptions?.length == 0;

            // Special rendering for the Select All and Reset All actions
            return (
              <li {...props} style={{ flexDirection: 'column', alignItems: 'start' }}>
                <Button
                  variant="text"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent dropdown from closing
                    handleSelectAll();
                  }}
                  disabled={allSelected} // Disable if all options are selected
                >
                  Alle auswählen
                </Button>
                <Button
                  variant="text"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent dropdown from closing
                    handleResetAll();
                  }}
                  disabled={noneSelected} // Disable if no options are selected
                >
                  Filter zurücksetzen
                </Button>
              </li>
            );
          }

          // Normal option rendering
          const isParent = option.has_children ? true : false;

          const numChildrenSelected = count_children_selected(
            option,
            filtersParamsToOptions(filtersParams, attributes)
          );

          return (
            <li {...props} className={isParent ? "parent" : ""}
            >
              <Checkbox
                icon={icon}
                checkedIcon={<CheckedIcon />}
                value={option.value}
                checked={selected}
              />
              {option.label}{" "}
              <strong>{isParent && (numChildrenSelected > 0 ? `${numChildrenSelected} ausgewählt` : "+")}</strong>
            </li>
          );

        }}

        renderInput={(props) => {
          let count = 0
          for (var key in filtersParams?.f) {
            if (key.startsWith(filterAttributeId) ) {
              console.log ("defaultFromUrl count for "+key,String(filtersParams?.f[key]))
              count += String(filtersParams?.f[key]).split(",").length
            }
          }

          return (
          <div className="badge-parent">
            <TextField
              {...props}
              label={""}
              placeholder={placeholder || label}
              variant="standard"
              />
            <Badge sx={{ mx: 0 }} badgeContent={count}></Badge>
          </div>)
        }}

      ></Autocomplete>
    </>
  );
};

export default AttributeFilterDropdown;
