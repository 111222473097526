import React from "react";
import { createRoot } from "react-dom/client";

// Import HashRouter and Route components from react-router-dom
import { HashRouter, Route, Routes } from "react-router-dom";

import { StyledEngineProvider } from '@mui/material/styles';

import App from "./App";
import JobAbo from "./JobAbo";
import "./index.css";
import { QueryClientProvider, QueryClient } from "react-query";

const container = document.getElementById("root");
const root = createRoot(container);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <StyledEngineProvider injectFirst>
      <HashRouter>
        <Routes>
          {/* Correctly assign App component to the root path */}
          <Route path="/" element={<App />} />
          {/* Assign JobAbo component to the "/jobabo" path */}
          <Route path="/jobabo" element={<JobAbo />} />
        </Routes>
      </HashRouter>
    </StyledEngineProvider>
  </QueryClientProvider>
);
