import React from "react";
import { Grid, List, ListItem, ListItemText, Skeleton } from "@mui/material";
import Favourite from "./Favourite";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const ArrowForwardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" stroke="#304567"/>
  </svg>
);

/**
 * Renders a single Job item, typically as part of a JobList.
 * This requires a "job" JSON object as a prop, as provided by the backend API.
 *
 *
 *
 * @param {job JSON object, as coming from the API} job
 * @param {subtitleAttribute} subtitleAttribute - the attribute to use as the subtitle (e.g. 80, to use job.attributes[80])
 * @param {favourites} favourites - "toggle" to show a favourite toggle button, "remove" to show the button as remove, "none" to hide the button
 * @returns JSX for the Job
 */
const JobListItem = ({
  job,
  subtitleAttribute,
  pensumAttribute,
  klinikAttribute,
  favourites = "toggle",
} = props) => {
  if (!job) {
    return <div>Invalid job, expected a job object</div>;
  }
  return (
    <ListItem className="job-list-item" key={job.id}>
      <a className="job" href={job.links.directlink} target="_blank">
        <div className="hoverOverlay"></div>
        <Grid container justifyContent="space-between">
          <Grid item xs={11} sm={10} md={10}>              
              <h2>
                <span>{job.title}</span><br />
                {job.attributes[pensumAttribute]?.join(", ")}
              </h2>
              <div className="jobData">
                <p><span>{job.attributes[klinikAttribute]?.join(", ")}</span></p> 
                <span>{job.attributes[subtitleAttribute]?.join(", ")}</span>
              </div>                            
          </Grid>
          <Grid className="icons" item xs={1} sm={2} md={2} container justifyContent="end" alignItems="center">
            {favourites == "toggle" && <Favourite job={job} />}
            {favourites == "remove" && (
              <Favourite job={job} checkedIcon={<DeleteForeverIcon />} />
            )}
            <ArrowForwardIcon />
          </Grid>
        </Grid>
      </a>
    </ListItem>
  );
};

export default JobListItem;
