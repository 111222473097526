import { useEffect, useState } from "react";
import translations from "./translations";
import settings from "../settings";

const queryString =
    window.location.search.length > 1 && window.location.search[0] === "?"
        ? window.location.search.substring(1)
        : window.location.search;

function getQueryStringParams(queryString) {
    return queryString.split("&").reduce((result, currentItem) => {
        const [param, value] = currentItem.split("=");
        result[param] = value;
        return result;
    }, {});
}

const queryParams = getQueryStringParams(queryString);
console.log("queryParams", queryParams);
const translationSet = translations[queryParams.lang]
    ? translations[queryParams.lang]
    : translations.de;

export const i18n = {
    translations: translationSet,
    lang: queryParams.lang || "de",
};

// const DEFAULT_FILTER_PARAMS = {
//   lang: language,
//   q: null,
//   offset: 0,
//   limit: JOBS_PAGE_SIZE,
//   workload: null,
//   // "f":
//   //   { 80: ["1003009","...."],
//   //     10: ["...", ""]
//   // attribute-based filters
// };

/**
 * Extract url Params
 * @returns
 */
export function useUrlParams() {
    const [urlParams, setUrlParams] = useState({});

    useEffect(() => {
        const queryString =
            window.location.search.length > 1 && window.location.search[0] === "?"
                ? window.location.search.substring(1)
                : window.location.search;
        const queryParams = getQueryStringParams(queryString);
        console.log ("defaultFromUrl queryParams", queryParams);

        const filterParams = {
            lang: queryParams.lang || "de",
            q: queryParams.q || null,
            offset: parseInt(queryParams.offset) || 0,
            limit: parseInt(queryParams.limit) || settings.jobsPageSize || 5,
            workload: null,
            f: {},
        };

        Object.entries(queryParams)
            .filter((e) => e[0].startsWith("f:"))
            .forEach((e) => {
                filterParams.f[e[0].substring(2)] = e[1].split(",");
            });

        if (!filterParams.f) {
            delete filterParams.f;
        }
        console.log ("defaultFromUrl filterParams", filterParams);
        setUrlParams(filterParams);
    }, []);

    return urlParams;
}