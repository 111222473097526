import React from "react";
import ResetFiltersButton from "./ResetFiltersButton";
import { i18n } from "../util/i18n";
import {Button, TextField, Box, IconButton} from "@mui/material";
import SearchIcon from '../assets/images/search.svg';
import InputBase from '@mui/material/InputBase';

/**
 * A React component that controls the text search filter
 */

const TextSearchFilterInput = ({ filterParams, setFilterParams, setTriggerSubmit }) => {
  const handleTextSearchChange = (event, value) => {
    // only update the q parameter, and set the offset to 0
    setFilterParams({ ...filterParams, q: event.target.value, offset: 0 });
    setTriggerSubmit(true);
  };

  return (
    <>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>            
            <InputBase
                fullWidth
                onChange={handleTextSearchChange}
                placeholder={i18n.translations.searchPlaceholder}
                variant="standard"
                value={filterParams?.q ? filterParams.q : ""}
            />
            { (filterParams["q"]) && ( 
                        <ResetFiltersButton
                              filtersParams={filterParams}
                              setFiltersParams={setFilterParams}
                              setTriggerSubmit={() => {setSelectedParent(null);setTriggerSubmit(true)}}
                              paramToReset="q"
                        />
              )}
            <img src={SearchIcon} alt="Search" />
        </Box>
    </>
  );
};

export default TextSearchFilterInput;
