export default {
  de: {
    appTitle: "Offene Stellen",
    openPositions: "offene-stellen",
    searchPlaceholder: "Stellenangebote durchsuchen",
    showFilters: "Filter anzeigen",
    hideFilters: "Filter schliessen",
    resetFilters: "Filter zurücksetzen",
    noJobs: "Keine passende Stelle gefunden",
    workloadLabel: "Pensum wählen",
    jobaboButtonLabel: "Job-Newsletter abonnieren",
    jobaboModalButtonLabel: "Diese Suche abonnieren",
    filterBerufsgruppeLabel: "Berufsgruppe",
    filterInstitutLabel: "Klinik / Institut",
    favJobsSendByEmail: "Per E-Mail versenden",
    favJobsRemoveAll: "Alle löschen",
    favJobsDrawerHeader: "Jobs gemerkt",
    favJobsEmailPrebody:
        "Schön, dass du dich für einen Job bei der KSA interessierst.\n" +
        "\n" +
        "Hier findest du deine ausgewählten Stellen:",
    favJobsEmailPostbody:
        "Wir freuen uns, wenn du dich bei uns bewirbst. Bei Fragen zur Stelle, melde dich gern bei der im Inserat angegebenen Kontaktperson.\n" +
        "\n" +
        "Vielleicht treffen wir uns ja schon bald persönlich bei der KSA.\n" +
        "\n" +
        "Viele Grüsse\n" +
        "Dein KSA Recruiting Team",
    jobaboEmailLabel: "Email",
    jobaboNameLabel: "Bezeichnung Job-Newsletter",
    jobaboSubscriptionFormTitle: "Job-Abo",
    jobaboSubscriptionFormText:
        "Wollen Sie regelmässig über neue Stellenangebote des KSA informiert werden? Eröffnen Sie Ihr individuelles Stellensuchprofil und Sie werden automatisch über passende Stellen informiert.",
    displayAsGrid: "Als Gitter anzeigen",
    loadmorejobs: "Mehr anzeigen",
  },

  en: {
    appTitle: "Available positions",
    openPositions: "open-positions",
    searchPlaceholder: "Search term",
    showFilters: "Show filters",
    hideFilters: "Hide filters",
    resetFilters: "Reset filters",
    noJobs: "No jobs found",
    workloadLabel: "Select workload range",
    jobaboButtonLabel: "Subscribe to Job-Newsletter",
    jobaboModalButtonLabel: "Subscribe to this search",
    filterBerufsgruppeLabel: "Professional group",
    filterInstitutLabel: "Clinique / Institute",
    favJobsSendByEmail: "Send by E-Mail",
    favJobsRemoveAll: "Remove all",
    favJobsDrawerHeader: "Jobs noted",
    favJobsEmailPrebody:
        "Schön, dass du dich für einen Job bei der KSA interessierst.\n" +
        "\n" +
        "Hier findest du deine ausgewählten Stellen:",
    favJobsEmailPostbody:
        "Wir freuen uns, wenn du dich bei uns bewirbst. Bei Fragen zur Stelle, melde dich gern bei der im Inserat angegebenen Kontaktperson.\n" +
        "\n" +
        "Vielleicht treffen wir uns ja schon bald persönlich bei der KSA.\n" +
        "\n" +
        "Viele Grüsse\n" +
        "Dein KSA Recruiting Team",
    jobaboEmailLabel: "Email",
    jobaboNameLabel: "Subscription name",
    jobaboSubscriptionFormTitle: "Subscribe to new jobs",
    displayAsGrid: "Display as grid",
    loadmorejobs: "Show more",
  },
};