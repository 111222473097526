import { Button, Checkbox, Drawer } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { i18n } from "../util/i18n";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";

import LocalStorageContext from "../util/LocalStorageContext";
import FavouriteDrawer from "./FavouriteDrawer";

const FavouriteCounterButton = ({ loading, bookmark = false, ...props }) => {
  if (loading) {
    return <></>;
  }

  // Use our (global) local storage context
  const [stored, setStored] = useContext(LocalStorageContext);

  // Number of fav jobs in the local storage
  const numFavourites = Object.keys(stored || {}).length;

  // open / close the drawer
  const [open, setOpen] = React.useState(false);

  const checkedIcon =
    props.checkIcon || bookmark ? (
      <BookmarkIcon color={numFavourites > 0 ? "success" : "disabled"} />
    ) : (
      <FavoriteIcon color={numFavourites > 0 ? "success" : "disabled"} />
    );

  const toggleDrawer = (event) => {
    setOpen(!open);
    document.getElementById('root').scrollIntoView();
  };

  return (
    <>
      <Button
        className="job-favourite-button"
        variant="contained"
        onClick={toggleDrawer}
        disabled={numFavourites == 0}
      >
        <FavouriteDrawer open={open} onClose={toggleDrawer} />
        {checkedIcon}
        <span>{numFavourites}</span>{" "}
      </Button>
    </>
  );
};

export default FavouriteCounterButton;
