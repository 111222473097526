import {
  Button,
  Checkbox,
  Drawer, Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { i18n } from "../util/i18n";

import LocalStorageContext from "../util/LocalStorageContext";
import JobList from "./JobList";
import Settings from "../settings";


/**
 * Collapsible Drawer component that shows a list of "Favourite Jobs" from local storage,
 * and buttons to:
 * - Create an email with a list of favourite jobs
 * - Clear all favourite jobs (remove from local storage)
 *
 * @param {*} param0
 * @returns
 */
const FavouriteDrawer = ({
  loading,
  bookmark = false,
  open = false,
  onClose,
  ...props
}) => {
  // Use our (global) local storage context
  const [stored, setStored] = useContext(LocalStorageContext);


  // Number of fav jobs in the local storage
  const numFavourites = Object.keys(stored || {}).length;

  // clone jobList
  let jobList = JSON.parse(JSON.stringify(Object.values(stored || {})));


  /**
   * Change the directlink urls into new ones from jobList, in case they have changed
   */
  let updatedJobList = jobList.map((job) => {
  
    //console.log("dl: Locally stored Favourite job.links.directlink:", job.links.directlink)
    //console.log("dl: directlinkBaseUrl:", Settings.directlinkBaseUrl);

    let newUrl = job.links.directlink;
    if (! job.links.directlink.startsWith(Settings.directlinkBaseUrl)) {
      // change the base Url, use path without /jobs
      newUrl = Settings.directlinkBaseUrl + new URL(job.links.directlink).pathname.substring(4)
      console.log("dl: Updated Favourite url:", job.links.directlink, newUrl)

    } else {
      console.log("dl: ALREADY correct Favourite url:",newUrl)
    }


    job.links.directlink = newUrl
    return job
  });

  const FILTER = Settings.filterIds;

  const handleDeleteAll = (event) => {
    console.log("deleting all favourite jobs", event);
    setStored({});
  };

  const constructEmail = () => {
    const favJobsEmailTitle =
      i18n.translations.favJobsEmailTitle || "Favourite Jobs";
    const preBody =
      i18n.translations.favJobsEmailPrebody ||
      "[Please set favJobsEmailPrebody in translations.js]";
    const postBody =
      i18n.translations.favJobsEmailPostbody ||
      "[Please set favJobsEmailPostbody in translations.js]";
    const body = jobList
      .map((job) => job.title + " ( " + job.attributes[20] + " )" + "\n" + job.links.directlink)
      .join("\n\n");

    const fullbody = encodeURIComponent(`${preBody}\n\n${body}\n\n${postBody}`);

    return `mailto:?subject=${favJobsEmailTitle}&body=${fullbody}`;
  };

  return (
    <>
      <Drawer anchor="top" open={open} onClose={onClose}>
        <Stack direction={"column"} spacing={2}>
          <div className="drawer-header">
            <Typography variant="h5">{numFavourites} {i18n.translations.favJobsDrawerHeader}</Typography>
          </div>
          <div className="drawer-body">
            <JobList jobs={updatedJobList} pensumAttribute={FILTER["Pensum"]} subtitleAttribute={FILTER["Subtitel"]}  klinikAttribute={FILTER["Klinik_Institut"]} favourites="remove" />
          </div>
          <div className="drawer-footer">
            <Grid container spacing={{ sm: 2, md: 6 }}>
              <Grid item xs={12} sm={12} md={4}>
              <Link
                    className="MuiButton-root MuiButton-contained"
                    href={constructEmail()}
                    underline="none"
                    sx={{ maxWidth: '240px' }}
                >
                  {i18n.translations.favJobsSendByEmail}
                </Link>
              </Grid>
              <Grid item xs={12} sm={12} md={3} container justifyContent="end">
                <Button 
                  className="MuiButton-root MuiButton-contained"
                  variant="outlined" onClick={handleDeleteAll}>
                  {i18n.translations.favJobsRemoveAll}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Drawer>
    </>
  );
};

export default FavouriteDrawer;