/***
 *
 * This library is used to query the Career Center JobLists from our API server.
 * API docs: ...
 *
 * Uses the following libraries:
 * - react-query (useQuery hook):
 *
 */

import { useQuery } from "react-query";
import React, { useState, useEffect } from "react";

// import our utility functions for working with filters and languages
import { i18n } from "./i18n";

const attributesToFilters = (attributes) => {
    return attributes;
};

/**
 *
 */
const loadFilters = (baseUrl, language = "de") => {
    return useQuery(["attributes"], () => {
        return fetch(`${baseUrl}/attributes?lang=${language}&returnValuesAsArray=1`)
            .then((res) => {
                if (!res.ok) {
                    console.log("Error fetching Filter attributes from ", res.url);
                    return Promise.reject(res);
                }
                console.log("Fetched attributes from ", res.url);
                return res.json();
            })
            .then((data) => {
                let filters = attributesToFilters(data);
                console.log("loaded filters", filters);
                return filters;
            });
    },
    {
        // Add options to prevent refetching on window focus
        refetchOnWindowFocus: false
    });
};

const loadJobs = (jobListFetchUrl, setTotalJobs) => {
    return useQuery(["joblist", jobListFetchUrl], async () => {
      try {
        const response = await fetch(jobListFetchUrl);
        if (!response.ok) {
          console.error("Error fetching jobs from", jobListFetchUrl, "Status:", response.status);
          throw new Error(`HTTP error, status = ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched jobs from", jobListFetchUrl);
        console.log("Received job list from server:", data);
        console.log("Total jobs:", data.total);
        setTotalJobs(data.total);
        return data;
      } catch (error) {
        console.log("Error in job fetch operation:", error.message);
        // Optionally re-throw the error if you want to handle it outside or show it in the UI.
        //throw error;

      }
    });
  };

export { loadFilters, loadJobs };