import {
  Autocomplete,
  Badge,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Stack
} from "@mui/material";
import React, { useState, useCallback } from "react";
import { i18n } from "../util/i18n";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;


const CustomExpandMoreIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 10L12 16L18 10" stroke="#304567" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const CheckedIcon = () => (
  <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckBoxIcon">
    <rect x="4" y="4" width="16" height="16" fill="#304567"></rect>
    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="white"></path>
  </svg>
);


/**
 * A flexible Autocomplete component for Attribute filters.
 * Supports single or multiple selections.
 * For multiple selections, the values are joined with a comma.
 */

/**
 * This converts the project filter attributes into a format that can be used with our Autocomplete.
 * It will return  a flat list, where hierarchical filters get a "group=true" property, and the value is the string concatenation of all child ids.
 * For all non-hierarchical filters, the value is the id of the of the attribute.
 */
const convertToOptionsList = (attributes, attributeId) => {

  //console.info("--- Attributes: ", attributes);

  let options = [];
  let sorted_values = [];
  let topLevelOptions = attributes
    .filter((item) => item.id == attributeId)
    .map((attribute) => attribute.sorted_values)[0];

  console.log("Top Level Options", topLevelOptions);

  topLevelOptions?.map((topLevelOption) => {
    let option = {
      id: topLevelOption["id"],
      label: topLevelOption["name"],
      value: topLevelOption["id"],
    };

    // push the top level option
    options.push(option);

    let childAttributes = attributes.find(
      (item) => item.parent_value_id == option.id
    )?.sorted_values;
    // do we have child attributes?
    if (childAttributes) {
      option.value = ""; // For groups, value should only include ids of children
      //console.log("--- Child Attributes for ", option.label, childAttributes);
      childAttributes.map((childAttribute) => {
        //console.log("Child Attribute", childAttribute);

        // append to parent option value
        option.group = true;
        option.value = option.value
          ? `${option.value},${childAttribute["id"]}`
          : childAttribute["id"];

        options.push({
          id: childAttribute["id"],
          label: childAttribute["name"],
          value: childAttribute["id"],
          parent: option.id,
        });
      });
    }
  });

  console.log("All available options", options);
  return options;
};

/**
 * Finds the Options within the options list that match the current filter value.
 * The selectedIdValues are a string of comma-separated ids.
 *
 * @param {*} allOptions
 * @param {*} selectedIdValues
 */
const getSelectedOptions = (allOptions, selectedIdValues) => {
  if (!selectedIdValues) {
    return [];
  }
  let selectedOptions = [];
  console.log("---", typeof selectedIdValues);

  selectedIdValues.split(",").map((id) => {
    let option = allOptions.find((item) => item.id == id);
    if (option) {
      selectedOptions.push(option);
    }
  });

  console.log("selected", selectedOptions);

  return selectedOptions;
};

const AttributeFilterAutocomplete = ({
  filtersParams,
  setFiltersParams,
  attributes,
  filterAttributeId,
  label = "Filter",
  placeholder,
  ...props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // Logic to convert attributes to options, get selected options, etc.

  const dropdownIcon = dropdownOpen ? <CloseIcon /> : <CustomExpandMoreIcon />;


  if (!attributes) {
    return <div>Project Attributes are required</div>;
  }

  if (!filterAttributeId) {
    return <div>Filter attribute ID is required</div>;
  }
  const filterId = filterAttributeId;
  //const filterName = "Klinik / Institut";
  const multiple = true;

  // Convert the attributes for this filter into a flat list of options
  const options = convertToOptionsList(attributes, filterId);
  // Now check which of these options are currently selected (by looking at the filter params)
  let selectedOptions = getSelectedOptions(
    options,
    filtersParams["f"] ? String(filtersParams["f"][`${filterId}`]) : ""
  );

  const handleChanged = (event, value) => {
    console.log("Value changed", value);
    let filterAttributeList = value.map((o) => o.value)//.join(",").split(",");
    console.log ("filterAttributeList", filterAttributeList)

    filterAttributeList = filterAttributeList.filter((id) => filterAttributeList.indexOf(id) === filterAttributeList.lastIndexOf(id));
    let newFilterParams = {
      ...filtersParams,
      f: {
        ...filtersParams.f,
        [`${filterId}`]: filterAttributeList//.join(","),
      },
      offset: 0,
    };
    
    if (filterAttributeList.length === 0) {
       delete newFilterParams.f[`${filterId}`]
    }

    setFiltersParams(newFilterParams);
  };

  // New functionality to select all or reset all
  const handleSelectAll = useCallback(() => {
    setFiltersParams((prevFiltersParams) => ({
      ...prevFiltersParams,
      f: {
        ...prevFiltersParams.f,
        [`${filterId}`]: options.map((option) => option.value)//.join(","),
      },
    }));
  }, [options, filterId, setFiltersParams]);

  const handleResetAll = useCallback(() => {
    setFiltersParams((prevFiltersParams) => {
      let result = {
        ...prevFiltersParams,
        f: {
          ...prevFiltersParams.f,
          [`${filterId}`]: [],
        },
      };
      delete result.f[`${filterId}`];
      return result
  });
  }, [filterId, setFiltersParams]);

  // see: https://mui.com/material-ui/api/autocomplete/
  return (
    <>
      <Autocomplete 
        disablePortal={false}    
        id={`attribute-filter-${filterId}`}
        fullWidth={true}
        limitTags={3}
        multiple={multiple}
        popupIcon={dropdownIcon}
        value={selectedOptions}
        onChange={handleChanged}
        options={[{ label: 'Actions', SelectAll: true }, ...options]} // Prepend a pseudo-option for actions
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
        renderTags={(value, getTagProps, ownerState) => (
          <Badge sx={{ mx: 0 }} badgeContent={ownerState.value.length}></Badge>
        )}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.label || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => {
          if (option.SelectAll) {
            // Determine if all options are selected or none are selected
            const allSelected = options.length === selectedOptions.length;
            const noneSelected = selectedOptions.length === 0;
  
            // Special rendering for the Select All and Reset All actions
            return (
              <li {...props} style={{ flexDirection: 'column', alignItems: 'start' }}>
                <Button 
                  variant="text" 
                  size="small" 
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent dropdown from closing
                    handleSelectAll();
                  }}
                  disabled={allSelected} // Disable if all options are selected
                >
                  Alle auswählen
                </Button>
                <Button 
                  variant="text" 
                  size="small" 
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent dropdown from closing
                    handleResetAll();
                  }}
                  disabled={noneSelected} // Disable if no options are selected
                >
                  Filter zurücksetzen
                </Button>
              </li>
            );
          }
  
          // Define styles conditionally before rendering
          const checkboxStyles = option.parent
            ? { marginLeft: -1, marginRight: 0, color: "red" }
            : { marginRight: -4 };
  
          // Normal option rendering
          return (
            <li {...props} className={option.parent ? "child" : "parent"}>
              <Checkbox
                icon={icon}
                checkedIcon={<CheckedIcon />}
                sx={checkboxStyles}
                checked={selected}                
              />
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={""}
            placeholder={placeholder || label}
            variant="standard"
          />
        )}
      />
    </>
  );
  
  
  
};

export default AttributeFilterAutocomplete;
