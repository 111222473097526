export default {
  baseUrl: "https://ohws.prospective.ch/public/v1/medium/1003009", // api end to fetch the jobs

  // If jobs.links.directlink in a stored Favourite does NOT start with this, the Url will be converted to
  // match this base Url
  directlinkBaseUrl: "https://jobs.ksa.ch",

  jobAboSubscriptionFormUrl:
    "https://ohws.prospective.ch/public/v1/careercenter/1003009/createJobAbo", // to submit the jobabo subscription form

  jobsPageSize: 15, // number of page shown per page

  enableFavourites: true, // enable the favourites feature

  localStorageKeyFavourites: "prospective-jobs-favourites", // key for local storage

  jobGrid: false, // enable the job grid view

  jobGridColumns: 3, // number of columns for the job grid view (lg and up)

  // If jobs.links.directlink in a stored Favourite does NOT start with this, the Url will be converted to
  // match this base Url
  directlinkBaseUrl: "https://jobs.ksa.ch",


  /**
   * TODO
   * Please add your filters and ids here, as defined for the project.
   * Your components will be able to access these ids via Settings.filterIds['Your filter Name'] or FILTER['Your name']
   */
  filterIds: {
    Subtitel: 10,
    Pensum: 20,
    Berufsgruppe: 70,
    Klinik_Institut: 80,
    Fachbereich: 90,
  },
};
