import React from "react";
import {useState} from "react";

import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  TextField, Typography,
} from "@mui/material";

import Settings from "../settings";

import { i18n } from "../util/i18n";
import JobAboSubmitButton from "./JobAboSubmitButton";
import FilterSection from "./FilterSection";

/**
 * Renders a a JobAbo Subscriptionm Form
 * @returns JSX
 */
const JobAboSubscriptionForm = ({
  filterParams,
  setFilterParams,
  attributes = null,
  loading = false,
  ...props
} = props) => {
  const [email, setEmail] = React.useState("");
  const [aboLabel, setAboLabel] = React.useState("");
  const [responseHtml, setResponseHtml] = useState ("")


  const [aboFilters, setAboFilters] = React.useState(filterParams);

  if (loading) {
    return (
      <div>
        <Skeleton width={"100%"} height={200} variant="wave" />
      </div>
    );
  }

  const handleSubmit = (event, value) => {
    event.preventDefault();
    const theForm = event.target;
    const formContainer = document.getElementsByClassName("formContainer");

    function sendData() {
      const XHR = new XMLHttpRequest();

      // Bind the FormData object and the form element
      const FD = new FormData(theForm);

      // Define what happens on successful data submission
      XHR.addEventListener("load", (event) => {
        formContainer.innerHTML = event.target;
        const response = new DOMParser().parseFromString(event.target.response, "text/html");
        const resultDiv = response.getElementById('jobabo-success').innerHTML
        setResponseHtml(resultDiv);

        console.log(resultDiv);
      });

      // Define what happens in case of error
      XHR.addEventListener("error", (event) => {
        alert('Oops! Something went wrong.');
      });

      // Set up our request
      XHR.open("POST", Settings.jobAboSubscriptionFormUrl + `?lang=${i18n.lang}`);

      // The data sent is what the user provided in the form
      XHR.send(FD);
    }
    sendData();

    //theForm.submit();
  };

  return (
    <div className={props.className}>
      {responseHtml &&
          <Box autoComplete="off">
            <Typography
                variant="h3">
              {i18n.translations.jobaboSubscriptionFormTitle}
            </Typography>
          <div className="" dangerouslySetInnerHTML={{__html: responseHtml}}/>
          </Box>
      }
      {!responseHtml &&
      <Box autoComplete="off">
        <Typography
            variant="h3">
          {i18n.translations.jobaboSubscriptionFormTitle}
        </Typography>

        <Typography
            variant="body">
          {i18n.translations.jobaboSubscriptionFormText}
        </Typography>
        <form
            className="jobaboForm"
            action={Settings.jobAboSubscriptionFormUrl + `?lang=${i18n.lang}`}
            method="post"
            autoComplete="off"
            onSubmit={handleSubmit}
        >
          <TextField
              id="jobabo_email"
              name="jobabo_email"
              placeholder={i18n.translations.jobaboEmailLabel}
              variant="filled"
              required
          />

          <TextField
              id="jobabo_bezeichnung"
              name="jobabo_bezeichnung"
              placeholder={i18n.translations.jobaboNameLabel}
              variant="filled"
              required
          />

          <FilterSection
              attributes={attributes}
              disableButtons={true}
              disableHeader={true}
              disableWorkload={true}
              filterParams={aboFilters}
              setFilterParams={setAboFilters}
          />

          {aboFilters["q"] && (
              <input key="q" type="hidden" name="query" value={aboFilters["q"]} />
          )}
          {aboFilters["workload"] && (
              <input
                  key="workload"
                  type="hidden"
                  name="workload"
                  value={aboFilters["workload"]}
              />
          )}

          {aboFilters["f"] &&
              Object.entries(aboFilters["f"]).map((filter) => (
                  <input
                      type="hidden"
                      key={filter[0]}
                      name={`filter_${filter[0]}`}
                      value={filter[1]}
                  />
              ))}

          <JobAboSubmitButton />
        </form>
      </Box>
      }
    </div>
  );
};

export default JobAboSubscriptionForm;
