import React, { useState, useEffect, useRef, createContext } from "react";

// import our utility functions for working with filters and languages
import { i18n, useUrlParams } from "./util/i18n";

// Service to fetch (filtered) jobs from the backend
import { loadFilters, loadJobs } from "./util/JobQueryService";

// Specific hooks
import { useLocalStorage } from "react-use";

// Context
import LocalStorageContext from "./util/LocalStorageContext";

// Our reusable components
import FilterSection from "./components/FilterSection";
import JobList from "./components/JobList";
import NoJobsFound from "./components/NoJobsFound";
import JobListPagination from "./components/JobListPagination";
import JobListLoadMore from "./components/JobListLoadMore";
import JobAboSubscriptionModal from "./components/JobAboSubscriptionModal";

// MUI components and other imports
import {
  Grid,
  LinearProgress,
  Link,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import FavouriteCounterButton from "./components/FavouriteCounterButton";
import settings from "./settings";
import JobGrid from "./components/JobGrid";
import { useLocation, useSearchParams } from "react-router-dom";

// 3. specify the page size for the job list
const JOBS_PAGE_SIZE = settings.jobsPageSize || 5;


// Workaround: if attributes are loaded, check if f-values need to get a key-suffix (e.g. 80_1234=value) 
/** 
 * Convert the URLSearchParams object to a filterParams object (once on page load) 
 */ 
function fromUrlSearchParams(location, attributes) { 
 
  let searchParams = new URLSearchParams(location.search); 
 
  let resultFilterParams = { 
    offset: parseInt(searchParams.get('offset')) || 0, 
    limit: parseInt(searchParams.get('limit')) || JOBS_PAGE_SIZE, 
    workload: searchParams.get('workload'), 
    q: searchParams.get('q'), 
    lang: searchParams.get('lang') || 'de', 
    f: {} 
  } 
 
  searchParams.forEach((value, key) => { 
    // Example f:80=1234,6789 
    // 
    // 1234 belongs directly to 80
    // 6789 belongs to 80_7777
    //
    
    // key = "80"
    // value = "1234,6789"
    if (key.startsWith('f:')) { 
      const filterKey = key.slice(2); // "80"
 
       // Set removes potential duplicates
      const idsToFind = new Set(value.split(',')); // ["1234", "6789"]
      console.log ("Ids to find for top-level filter key", filterKey, idsToFind) 
 
      // Now fo the ugly part: we may need to adjust the keys of filtersParams.f. 
      if (attributes) { 
        //let newParams = { f: {} } 
         
        //let filtered = attributes.filter((a) => a.id.startsWith(filterKey)); 
        //console.log("Filtered attributes", filtered) 
         
        const result = attributes.reduce((acc, item) => { 
          // Ensure item.values is defined and is an object before proceeding 
          if (item.values && typeof item.values === 'object') { 

            // foundKeys : id is added under "itemn.id", if
            const foundKeys = Object.keys(item.values)  //
                                    .filter(key => idsToFind.has(key)); 


            if (foundKeys.length > 0) { 
              acc[item.id] = foundKeys; 
            } 
          } 
          return acc; 
        }, {}); 
        console.log("Filtered attributes - result", result) 
 
 
        // result: { "80": ["1234"], 
        //           "80_7777": ["6789"]}
 
        resultFilterParams = { 
          ...resultFilterParams, 
          f: { 
            ...resultFilterParams.f, 
            ...result 
          } 
        } 
      } 
    } 
  }); 
 
 
 
  console.log("Filterparams changed, new URL adjusted fromUrlSearchParams", resultFilterParams); 
 
 
 
  return resultFilterParams; 
}

// function toUrlSearchParams (filterParams) {
//   const searchParams = new URLSearchParams();


//   return searchParams;
// }


/**
 *
 *
 */
function App() {
  // 1. Base URL for this project / environment.
  const baseUrl = settings.baseUrl; // "https://stage.ohws.prospective.ch/public/v1/medium/1003009";
  const language = i18n.lang;

  // 2. define constants for filter ids here
  const FILTER = settings.filterIds;

  // This will get updated with the filters, required for pagination, jump to page etc.
  const [totalJobs, setTotalJobs] = useState(0);

  // 4. Here we define the filter GET parameters for the job query service
  const location = useLocation();

  //const [searchParams, setSearchParams] = useSearchParams()
  const [filterParams, setFilterParams] = useState(fromUrlSearchParams(location,null));


  // const DEFAULT_FILTER_PARAMS = {
  //   lang: language,
  //   q: null,
  //   offset: 0,
  //   limit: JOBS_PAGE_SIZE,
  //   workload: null,
  //   // "f":
  //   //   { 80: ["1003009","...."],
  //   //     10: ["...", ""]
  //   // attribute-based filters
  // };
  
  // Whenever filterParams changes, convert to UrlSearchParams and update
  useEffect(() => {
    //const newSearchParams = toUrlSearchParams (filterParams);
    //setSearchParams (newSearchParams);

    // initially we don't have any filterParams, so we don't want to update the jobListFetchUrl
    //if (filterParams && filterParams.limit) {
      let url = createFetchUrl(baseUrl, filterParams);
      console.log("Filterparams changed, new URL: ", filterParams, url);
      setJobListFetchUrl(url);
    //}
  }, [filterParams, setFilterParams]);

  const createFetchUrl = (baseUrl, params) => {
    // A bit hacky: "f:<filterId1>=123,456&f:<filterId2>=888,999&...""
    // TODO clean this up
    //var F = Object.entries(params).filter((key) => params[key] == "f");
    const mergeValuesWithSameKey = (params) => {
      const result = {};
  
      for (const [key, value] of Object.entries(params)) {
          const mainKey = key.split("_")[0];
  
          if (!result[mainKey]) {
              result[mainKey] = value;
          } else {
              result[mainKey] = `${result[mainKey]},${value}`;
          }
      }
  
      return result;
  }
    var url = `${baseUrl}/jobs?` +
    Object.keys(params)
        .filter((key) => params[key] || key == "offset") // always add offset
        .map((key) => {
          if (key == "f") {
                // // handle Filter: 
                // // `params` looks like this: {"80": "123,456", "80_5555": "789", "90": "555,666"} 
                // return Object.entries(params[key]) 
                //     .map((e) => `f=${e[0].split("_")[0]}:${e[1]/*.join(",")*/}`) // e = [80, "123,456,789"] 
                //     .join("&")

                // merge params keys, e.g.
                //    {"80": "123,456", "80_5555": "789", "90": "555,666"}
                // will become 
                //    {"80": "123,456,789", "90": "555,666"}
                const mergedParams = mergeValuesWithSameKey(params.f);

                return Object.entries(mergedParams)
                    .map(([key, value]) => `f=${key}:${value}`)
                    .join("&");
          }
          else {
            // Simple field like, q or workload: return like `workload=[10,100]` or `q=MyText` 
            return `${key}=${params[key]}`;
          }
        })
        .join("&");
  

    return url;
  };


  // This is the current, full URL for the job GET request
  const [jobListFetchUrl, setJobListFetchUrl] = useState(null);

  // Here we define triggerSubmit state to differentiate between initial no-results and no-results after submit
  const [triggerSubmit, setTriggerSubmit] = useState(false)

  // whenever the filterParams change, we need to update the jobListFetchUrl.
  // filterParams will change when the user changes the filters, or when the URL changes (defaultFilterParamsFromUrl)
  // useEffect(() => {
  //   // initially we don't have any filterParams, so we don't want to update the jobListFetchUrl
  //   if (filterParams && filterParams.limit) {
  //     let url = createFetchUrl(baseUrl, filterParams);
  //     console.log("Filterparams changed, new URL: ", filterParams, url);
  //     setJobListFetchUrl(url);
  //   }
  // }, [filterParams]);

  // Local storage state for the job favourites
  const [localStorageFavourites, setLocalStorageFavourites] = useLocalStorage(
      settings.localStorageKeyFavourites,
      {}
  );

  const { isLoading, isError, data } = loadJobs(jobListFetchUrl, setTotalJobs);

  // Load available filter options (usually only once)
  const {
    isLoading: isLoadingFilters,
    isError: isErrorFilters,
    data: currentFilters,
  } = loadFilters(baseUrl, language);

  useEffect (() => {
      if (currentFilters?.attributes) {
        console.log ("Updating currentFilter")
        setFilterParams (fromUrlSearchParams(location,currentFilters.attributes))
      }
  }, [currentFilters]);

  /***
   *
   * Return the JSX for the App
   *
   *
   */

  const [displayGrid, setDisplayGrid] = useState(settings.jobGrid);

  
  // Insert # into prefiltered URLs
  function convertURL(url) {
    const pattern = /(\?f:[^&]*=.*)/;
    if (!url.includes('#') && pattern.test(url)) {
        return url.replace(pattern, '#/$1');
    }
    return url;
  }
  // Reading the current URL from the browser, converting it, and substituting it
  const currentURL = window.location.href;
  const newURL = convertURL(currentURL);
  if (newURL !== currentURL) {
      window.history.pushState({ path: newURL }, '', newURL);
      window.location.reload(); // Reload the page to apply the new URL logic
  }

  return (
      <LocalStorageContext.Provider
          value={[localStorageFavourites, setLocalStorageFavourites]}
      >
        <Grid container className="wrapper">
          {isLoadingFilters && <LinearProgress />}
          {isError && <p>Error loading Jobs: {isError} ...</p>}
          {currentFilters && (
              <>
                <FilterSection
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    setTriggerSubmit={setTriggerSubmit}
                    attributes={currentFilters.attributes}
                />
                <Grid container justifyContent="space-between" marginBottom={8}>
                      <Grid item>
                            <JobAboSubscriptionModal
                              filterParams={filterParams}
                              setFilterParams={setFilterParams}
                              attributes={currentFilters.attributes}
                            />
                      </Grid>
                      {settings.enableFavourites && (
                          <Grid item xs={2} sm={2} container justifyContent="end">
                                <FavouriteCounterButton loading={!data} />
                          </Grid>
                      )}
                </Grid>
              </>
          
          )}

          {/* Unncomment if the feature needed
          {i18n.translations["displayAsGrid"]}
          <Switch onChange={() => setDisplayGrid(!displayGrid)}></Switch>
          */}

          <div className="jobsList">
              {isLoading && <LinearProgress />}

              {data ? (
                  data.jobs.length > 0 ? (
                      <>
                          <JobList
                              jobs={data.jobs}
                              subtitleAttribute={FILTER["Subtitel"]}
                              pensumAttribute={FILTER["Pensum"]}
                              klinikAttribute={FILTER["Klinik_Institut"]}
                              favourites={settings.enableFavourites ? "toggle" : "none"}
                          />
                          <JobListPagination
                              filterParams={filterParams}
                              setFilterParams={setFilterParams}
                              setTriggerSubmit={setTriggerSubmit}
                              totalJobs={totalJobs}
                          />
                      </>
                  ) : (
                      <NoJobsFound submitted={triggerSubmit} />
                  )
              ) : (
                  <Skeleton
                      sx={{ bgcolor: "white" }}
                      variant="rectangular"
                      width={"100%"}
                      height={"500px"}
                  />
              )}
          </div>
        </Grid>
      </LocalStorageContext.Provider>
  );
}

export default App;