import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { i18n } from "../util/i18n";

const NoJobsFound = ({submitted}) => {
  return (
    <>
      <Typography variant="h5" marginBottom={8}>{i18n.translations.noJobs}</Typography>
      {!submitted && (
        <>
          <a className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-disableElevation jobabo-button MuiButtonBase-root-MuiButton-root" href="https://www.ksa.ch/de/kantonsspital-aarau/karriere-bildung/bewerben/offene-stellen" target="_blank">
            Zum allgemeinen Stellenmarkt
            <span className="MuiTouchRipple-root MuiTouchRipple-root"></span>
          </a>
        </>
      )}
    </>
  );
};

export default NoJobsFound;
