import { Button } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

/**
 * A React component that resets all filters to their default values
 * or a specific filter parameter if provided.
 */

const ResetFiltersButton = ({
  filtersParams,
  setFiltersParams,
  setTriggerSubmit,
  paramToReset = "",
}) => {
  const handleReset = () => {
    let newFiltersParams;

    if (paramToReset) {
      // Reset the specified parameter
      newFiltersParams = {
        ...filtersParams,
        [paramToReset]: undefined,
        lang: filtersParams.lang,
        limit: filtersParams.limit,
        offset: 0,
      };
    } else {
      // Reset all parameters except lang, limit, and reset offset to 0
      newFiltersParams = {
        lang: filtersParams.lang,
        limit: filtersParams.limit,
        offset: 0,
      };
    }

    setFiltersParams(newFiltersParams);
    setTriggerSubmit(true); // Trigger a reload
  };

  return (
    <Button className="reset-filters-button" onClick={handleReset}>
      <CloseIcon />
    </Button>
  );
};

export default ResetFiltersButton;
