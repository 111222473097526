import React, { useState, useEffect, useRef, createContext } from "react";

// import our utility functions for working with filters and languages
import { i18n, useUrlParams } from "./util/i18n";

// Service to fetch (filtered) jobs from the backend
import { loadFilters, loadJobs } from "./util/JobQueryService";

// Specific hooks
import { useLocalStorage } from "react-use";

// Context
import LocalStorageContext from "./util/LocalStorageContext";

// Our reusable components
import FilterSection from "./components/FilterSection";
import JobList from "./components/JobList";
import NoJobsFound from "./components/NoJobsFound";
import JobListPagination from "./components/JobListPagination";
import JobListLoadMore from "./components/JobListLoadMore";
import JobAboSubscriptionForm from "./components/JobAboSubscriptionForm";

// MUI components and other imports
import {
  Grid,
  LinearProgress,
  Link,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import FavouriteCounterButton from "./components/FavouriteCounterButton";
import settings from "./settings";
import JobGrid from "./components/JobGrid";


/**
 *
 *
 */
function App() {
  // 1. Base URL for this project / environment.
  const baseUrl = settings.baseUrl; // "https://stage.ohws.prospective.ch/public/v1/medium/1003009";
  const language = i18n.lang;

  // 2. define constants for filter ids here
  const FILTER = settings.filterIds;

  // 3. specify the page size for the job list
  const JOBS_PAGE_SIZE = settings.jobsPageSize || 5;
  // This will get updated with the filters, required for pagination, jump to page etc.
  const [totalJobs, setTotalJobs] = useState(0);

  // 4. Here we define the filter GET parameters for the job query service
  // (also for your reference how the object looks like)
  const defaultFilterParamsFromUrl = useUrlParams(null);

  const DEFAULT_FILTER_PARAMS = {
    lang: language,
    q: null,
    offset: 0,
    limit: JOBS_PAGE_SIZE,
    workload: null,
    // "f":
    //   { 80: ["1003009","...."],
    //     10: ["...", ""]
    // attribute-based filters
  };

  const [filterParams, setFilterParams] = useState(null);
  useEffect(() => {
    if (defaultFilterParamsFromUrl) {
      setFilterParams(defaultFilterParamsFromUrl);
    }
  }, [defaultFilterParamsFromUrl]);

  const createFetchUrl = (baseUrl, params) => {
    // A bit hacky: "f:<filterId1>=123,456&f:<filterId2>=888,999&...""
    // TODO clean this up
    //var F = Object.entries(params).filter((key) => params[key] == "f");
    const mergeValuesWithSameKey = (params) => {
      const result = {};
  
      for (const [key, value] of Object.entries(params)) {
          const mainKey = key.split("_")[0];
  
          if (!result[mainKey]) {
              result[mainKey] = value;
          } else {
              result[mainKey] = `${result[mainKey]},${value}`;
          }
      }
  
      return result;
  }
    var url = `${baseUrl}/jobs?` +
    Object.keys(params)
        .filter((key) => params[key] || key == "offset") // always add offset
        .map((key) => {
          if (key == "f") {
                // // handle Filter: 
                // // `params` looks like this: {"80": "123,456", "80_5555": "789", "90": "555,666"} 
                // return Object.entries(params[key]) 
                //     .map((e) => `f=${e[0].split("_")[0]}:${e[1]/*.join(",")*/}`) // e = [80, "123,456,789"] 
                //     .join("&")

                // merge params keys, e.g.
                //    {"80": "123,456", "80_5555": "789", "90": "555,666"}
                // will become 
                //    {"80": "123,456,789", "90": "555,666"}
                const mergedParams = mergeValuesWithSameKey(params.f);

                return Object.entries(mergedParams)
                    .map(([key, value]) => `f=${key}:${value}`)
                    .join("&");
          }
          else {
            // Simple field like, q or workload: return like `workload=[10,100]` or `q=MyText` 
            return `${key}=${params[key]}`;
          }
        })
        .join("&");
  

    return url;
  };


  // This is the current, full URL for the job GET request
  const [jobListFetchUrl, setJobListFetchUrl] = useState(null);

  // Here we define triggerSubmit state to differentiate between initial no-results and no-results after submit
  const [triggerSubmit, setTriggerSubmit] = useState(false)

  // whenever the filterParams change, we need to update the jobListFetchUrl.
  // filterParams will change when the user changes the filters, or when the URL changes (defaultFilterParamsFromUrl)
  useEffect(() => {
    // initially we don't have any filterParams, so we don't want to update the jobListFetchUrl
    if (filterParams && filterParams.limit) {
      let url = createFetchUrl(baseUrl, filterParams);
      console.log("Filterparams changed, new URL: ", filterParams, url);
      setJobListFetchUrl(url);
    }
  }, [filterParams]);

  const { isLoading, isError, data } = loadJobs(jobListFetchUrl, setTotalJobs);

  // Load available filter options (usually only once)
  const {
    isLoading: isLoadingFilters,
    isError: isErrorFilters,
    data: currentFilters,
  } = loadFilters(baseUrl, language);

  /***
   *
   * Return the JSX for the App
   *
   *
   */

  const [displayGrid, setDisplayGrid] = useState(settings.jobGrid);

  return (
        <Grid container className="jobabo-standalone">
          {isLoadingFilters && <LinearProgress />}
          {isError && <p>Error loading Jobs: {isError} ...</p>}
          {currentFilters && (
            <Grid item>
              <JobAboSubscriptionForm
                attributes={currentFilters.attributes}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
              />  
            </Grid>
          )}
        </Grid>
  );
}

export default App;