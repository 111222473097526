import React from "react";
import { Grid, List, ListItem, ListItemText, Skeleton } from "@mui/material";
import Favourite from "./Favourite";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import JobListItem from "./JobListItem";

const ArrowForwardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" stroke="#304567"/>
  </svg>
);

/**
 * Renders a list of jobs, based on the JSON object passed in the props
 * @param {jobs JSON object, as coming from the API} jobs
 * @returns JSX for the JobList
 */
const JobList = ({
  jobs,
  subtitleAttribute,
  pensumAttribute,
  klinikAttribute,
  loading = false,
  favourites = "toggle",
} = props) => {
  const jobList = jobs ? jobs : [];
  //console.log(jobList);

  if (!Array.isArray(jobs)) {
    return <div>Invalid jobs list, expected an array of job objects</div>;
  }

  if (loading) {
    return (
      <div>
        <Skeleton width={"100%"} height={200} variant="wave" />
      </div>
    );
  }

  return (
    <List className="job-list">
      {jobList.length > 0 &&
        jobList.map((job) => (
          <JobListItem
            key={job.id}
            job={job}
            subtitleAttribute={subtitleAttribute}
            pensumAttribute={pensumAttribute}
            klinikAttribute={klinikAttribute}
            favourites={favourites}
          />
        ))}
      {!jobList && <div>No jobs found</div>}
    </List>
  );
};

export default JobList;
